/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, Container, LinearProgress, Grid, Dialog, DialogTitle, DialogContent, Button, Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'moment';
import uiActions from '../../../../../../redux/actions/uiActions';
import TestBody from './components/TestBody';
import TestFinished from './components/TestFinished';
import LandingExam from './components/LandingExam';
import examType1Services from '../../../../../../redux/services/examType1Services';
import config from '../../../../../../config';
import examType1Actions, { checkExamBeginTime } from '../../../../../../redux/actions/examType1Actions';
import ExamTimer from './components/ExamTimer';
import { Loading } from '../../../../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    marginTop: theme.spacing(1),
    position: 'relative',
    marginBottom: '80px',
  },
  progressBar: {
    width: '100%',
    height: '5px',
  },
  mainTitle: {
    marginTop: '1em',
    textAlign: 'center',
  },
  image: {
    width: '25%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  textCard: {
    margin: '2em',
    padding: '2em',
    maxWidth: '420px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  h5: {
    color: 'blue',
  },
  button: {
    margin: '20px auto 20px auto',
  },
}));

// const findDuration = ()

const PlacementTest = ({
  changePageTitle,
  exam,
  isTest,
  currentQuestion,
  currentExam,
  answers,
  currentGroup,
  _id,
  activityId,
  additionalTime,
  setFinished,
  setStarted,
  clearAnswers,
}) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [isExamFinished, setIsExamFinished] = useState({ finished: false, date: '', examId: '' });
  const [timeCheck, setTimeCheck] = useState(null);
  const [timeout, setTimeOut] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [shown, setShown] = useState(false);
  const [timeLeft, setTimeLeft] = useState((currentExam.duration || 90) + additionalTime);
  const [loading, setLoading] = useState(true);

  const dates = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  const getFormatedDate = (_date) => {
    const date = Moment(_date);
    const day = date.date();
    const month = dates[date.month()];
    const year = date.year();
    const hours = date.hours();
    const minutes = date.minutes();

    return `${hours}h${minutes < 10 && minutes > 0 ? '0' : ''}${minutes > 0 ? minutes : ''} - ${day} ${month}, ${year}`;
  };

  useEffect(() => {
    examType1Services.isExamFinished({
      userId: _id,
      poolId: activityId,
    })
      .then((res) => {
        if (res !== null && res.length > 1) {
          const result = res[res.length - 1];
          if (result.isFinished) {
            // new Intl.DateTimeFormat('en-GB').format(res.updated_at);
            setIsExamFinished({
              finished: true,
              date: getFormatedDate(new Date(result.updated_at)),
              examId: result._id,
            });
          }
        } else if (res !== null && res.length === 1 && res[0].isFinished) {
          // new Intl.DateTimeFormat('en-GB').format(res.updated_at);
          setIsExamFinished({
            finished: true,
            date: getFormatedDate(new Date(res[0].updated_at)),
            examId: res[0]._id,
          });
        } else {
          setIsExamFinished({
            finished: false,
            date: '',
            examId: '',
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setErrorSnackBar({ code: 1801, message: 'Missing exam data.', severity: 'error' });
      });
  }, [exam, currentGroup]);

  useEffect(() => {
    changePageTitle('Examen');
  }, [isTest]);

  useEffect(() => {
    if (exam) {
      if (answers && answers.length > 0 && exam.questions && exam.questions.length > 0) {
        let found = true;
        answers.forEach((answer) => {
          exam.questions.forEach((question) => {
            if (question._id.toString() !== answer.question.toString()) {
              found = false;
            }
          });
        });
        if (found === false) {
          clearAnswers();
        }
      }
      setStarted(true);
    }
  }, [exam]);

  useEffect(async () => {
    const _res = checkExamBeginTime(additionalTime, currentExam.duration || 90);
    const _minutes = Math.round(_res / 1000 / 60);
    setTimeLeft(_minutes);
    const interval = setInterval(async () => {
      const res = checkExamBeginTime(additionalTime, currentExam.duration || 90);
      const minutes = Math.round(res / 1000 / 60);
      setTimeLeft(minutes);
      if (minutes === 5 && shown === false) {
        setOpen(true);
        setShown(true);
      }
      if (res !== undefined && res === false) {
        const body = {
          answers,
          time: 0,
          isFinished: true,
        };
        examType1Services.isExamFinished({
          userId: _id,
          poolId: activityId,
        })
          .then((resExam) => {
            examType1Services.sendTest(resExam[0]._id, body)
              .then(() => {
                setIsExamFinished({
                  finished: true,
                  date: getFormatedDate(new Date()),
                  examId: resExam[0]._id,
                });
                clearInterval(interval);
                clearInterval(res);
                clearInterval(timeCheck);
                localStorage.removeItem('examBeginTime');
                setTimeCheck(null);
                setTimeOut(true);
              })
              .catch(() => {
                setErrorSnackBar({ code: 1802, message: 'Error sending exam.', severity: 'error' });
              });
          })
          .catch(() => {
            setErrorSnackBar({ code: 1801, message: 'Missing exam data.', severity: 'error' });
          });
      }
    }, 60000);
    setTimeCheck(interval);
  }, []);

  return (
    <>
      {loading === false
        ? (
          <Container>
            <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
                {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
              </Alert>
            </Snackbar>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
            >
              <DialogTitle>Examen bientôt terminé.</DialogTitle>
              <DialogContent>{`Il vous reste moins de ${timeLeft} minutes pour terminer l'examen.`}</DialogContent>
              <Button onClick={() => setOpen(false)} autoFocus>
                J&apos;AI COMPRIS
              </Button>
            </Dialog>
            {
              !isExamFinished.finished && exam && currentQuestion !== exam.questions.length && !timeout
                ? <ExamTimer timeLeft={timeLeft} />
                : null
            }
            {!isExamFinished.finished
              ? (
                <Card variant="outlined" className={classes.root}>
                  {exam
                    ? ((currentQuestion === exam.questions.length || timeout)
                      ? (
                        <TestFinished setFinished={setFinished} testId={exam.testId} answers={answers} />
                      )
                      : (
                        <TestBody
                          descriptionRC={exam.descriptionRC}
                          descriptionKN={exam.descriptionKN}
                          testData={exam.questions}
                          testId={exam.testId}
                          answers={answers}
                        />
                      )
                    )
                    : (
                      <LandingExam activityId={activityId} />
                    )}
                  <LinearProgress className={classes.progressBar} variant="determinate" value={exam ? ((currentQuestion / exam.questions.length) * 100) : 0} />
                </Card>
              )
              : (
                <Grid container direction="row" justifyContent="center" style={{ marginTop: '50px' }}>

                  <Grid item md={12}>
                    <img className={classes.image} alt="placement test illustration" src={`${config.OBJECT_URL}/thumbnail/pt-landing.svg`} />
                  </Grid>

                  <Grid item md={12} className={classes.textCard}>
                    <Alert severity="info">
                      Votre examen a été envoyé à
                      {' '}
                      {isExamFinished.date}
                    </Alert>
                    {/* <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  component={Link}
                  to={{
                    pathname: '/elearning/exam/results',
                    state: { examId: isExamFinished.examId },
                  }}
                >
                  Voir le détail des résultats
                </Button> */}
                  </Grid>
                </Grid>
              )}
          </Container>
        )
        : <Loading />}
    </>
  );
};

PlacementTest.propTypes = {
  _id: PropTypes.string.isRequired,
  changePageTitle: PropTypes.func.isRequired,
  clearAnswers: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  isTest: PropTypes.bool.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  answers: PropTypes.array.isRequired,
  currentGroup: PropTypes.number.isRequired,
  additionalTime: PropTypes.number.isRequired,
  activityId: PropTypes.string.isRequired,
  setFinished: PropTypes.func.isRequired,
  setStarted: PropTypes.func.isRequired,
  currentExam: PropTypes.any.isRequired,
};

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
  clearAnswers: examType1Actions.clearAnswers,
};

function mapState(state) {
  const { authData, isTest } = state.auth;
  const {
    requestingExam, exam, answers, currentQuestion,
  } = state.exam;
  const { _id } = authData;
  const { currentGroup } = state.ui;
  const { additionalTime } = state.user.userData;
  return {
    _id, requestingExam, exam, isTest, answers, currentQuestion, currentGroup, additionalTime,
  };
}

export default connect(mapState, actionCreators)(PlacementTest);
